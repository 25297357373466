import React, { createContext, useContext, useState } from "react";
import BouncingDotsLoader from ".";

const LoaderContext = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <LoaderContext.Provider value={{ setShowLoader }}>
      <>
        {children}
        <BouncingDotsLoader isModalVisible={showLoader} />
      </>
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => {
  const { setShowLoader } = useContext(LoaderContext);
  return {
    setShowLoader,
  };
};
