
import React, { useState } from "react";
import { Button, DatePicker, Popover, Select } from "antd";
import { FloatLabel, SvgIcon } from "../../../components/common";
import "./index.scss";




const DatePickerPopover = (props) => {
  
  const { Option } = Select;

  const [DateFrom, setDateFrom] = useState(null);
  const [DateTo, setDateTo] = useState(null);
  const handleFrom = (date, dateString) => {
    setDateFrom(date);
  };
  const handleTo = (date, dateString) => {
    setDateTo(date);
  
    if (DateFrom) {
        console.log(DateFrom.format("DD-MM-YYYY"));
    }
  
  
  };


  return (
    <Popover
      overlayInnerStyle={{
        borderRadius: "8px",
      }}
      content={
        <div className="date-picker-content">
          <Select
            popupClassName="foo"
            defaultValue="All"
            placement="bottomRight"
            suffixIcon={<SvgIcon name="select-arrow" viewbox="0 0 18 9" />}
          >
            <Option value="All">6 Months</Option>
            <Option value="In-app payment">1 Year</Option>
          </Select>

          <span className="or-text">Or</span>
          <div className="start-date date-picker-float-label">
            <label>Starting Date</label>
            <DatePicker
              size="large"
              value={DateFrom} onChange={handleFrom} inputReadOnly placeholder="DD/MM/YYYY" format="DD/MM/YYYY"
              suffixIcon={<SvgIcon name="calendar" viewbox="0 0 38.269 36" />}
            />
          </div>

          <div className="end-date date-picker-float-label">
            <label>End Date</label>
            <DatePicker
              value={DateTo} onChange={handleTo} inputReadOnly placeholder="DD/MM/YYYY" format="DD/MM/YYYY"
              size="large"
              suffixIcon={<SvgIcon name="calendar" viewbox="0 0 38.269 36" />}
            />
          </div>

          <Button className="generate-button" type="primary" onClick={props.handleDateRange}>
            Generate Statement
          </Button>
        </div>
      }
    >
      <div className='date-select-row'>
                        <div className='date-col'>
                            <label>Starting Date</label>
                            <DatePicker value={DateFrom} onChange={handleFrom} inputReadOnly placeholder="DD/MM/YYYY" format="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                        <div className='date-col'>
                            <label>End Date</label>
                            <DatePicker value={DateTo} onChange={handleTo} inputReadOnly placeholder="DD/MM/YYYY" format="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
      </div>
    </Popover>
  );
};

export default DatePickerPopover;
