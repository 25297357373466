import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Input, Select, Table, Dropdown, Menu,Popover} from 'antd';
import { Col, Row, SvgIcon } from '../../components/common';

import { format } from 'date-fns';
import './index.scss';
import HttpClient from "../../api/HttpClient";
import helpTag from '../../assets/images/headphone.svg';

import { useLoaderContext } from '../Loader/LoaderContext';
import tpIcon from '../../assets/images/tp_icon.svg';
import { useSideBarMenuContext } from '../../layouts/SideBarMenuContext';
import DatePickerPopover from "./DatePickerPopover";

const OutletMgt = () => {

    var dataInf = [{ "iBrandID": "", "vBrandName": "" }];
    var merchantName = localStorage.getItem('vMerchantName');
    const [isLoadingInitialData, setIsLoadingInitialDate] = useState(true)
    const [totalSales, setTotalSales] = useState(0);
    const [totalRedemptions, setTotalRedemptions] = useState(0);
    const [avgRedemptions, setAvgRedemptions] = useState(0);
    const [totalOutlet, setTotalOutlet] = useState(0);
    const [outletData, setOutletData] = useState([]);
    const [BrandInfo, setBrandInfo] = useState(dataInf);
    const [merchantImage, setmerchantImage] = useState('');
    const [MenuText, setMenuText] = useState('All Time Report');
    const [Selected, setSelected] = useState("");
    const [dataSource, setDataSource] = useState([])
    const { setShowLoader } = useLoaderContext();
    const {isMyOffersVisible,setMyOffersVisible} = useSideBarMenuContext()
    const nowDate = new Date();
    const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    const currentDate = longEnUSFormatter.format(nowDate);
    var iBrandID = localStorage.getItem("iBrandID");
    const { Option } = Select;
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
       
        for (let index = 0; index < BrandInfo.length; index++) {
        
            const brand = BrandInfo[index];
            if(iBrandID===null)
            setSelected('all');
            if (brand.iBrandID === iBrandID) {
                //console.log(iBrandID);
                setSelected(iBrandID);
                break;
            }
        }
    
}, [BrandInfo])
    useEffect(() => {
        setDataSource(outletData);
    }, [outletData]);


    const fetchData = async () => {
        try {
            setShowLoader(true);
            setIsLoadingInitialDate(true);
            if (iBrandID !== null) {
                const data = new FormData();
                data.append("start", 0);
                data.append("limit", 20);
                data.append("iBrandID", iBrandID);
                const response = await HttpClient.post("/data/outletDataBrand", data);
                const json = response.data;

                if (json.status === 1) {
                    //console.log(json.voucherList);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setOutletData(json.outletList);
                    setTotalOutlet(json.your_stats.total_outlet);
                    setmerchantImage(json.merchant_info.merchant_image);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                        setMyOffersVisible(true);
                    }
                } else {
                    console.error(json);
                }
            }
            else {
                const data = new FormData();
                data.append("start", 0);
                data.append("limit", 20);
                const response = await HttpClient.post("/data/outletData");
                const json = response.data;

                if (json.status === 1) {
                    //console.log(json.voucherList);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setOutletData(json.outletList);
                    setTotalOutlet(json.your_stats.total_outlet);
                    setmerchantImage(json.merchant_info.merchant_image);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report');
                        setMyOffersVisible(false);
                    }
                } else {
                    console.error(json);
                }

            }

        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
            setIsLoadingInitialDate(false)
        }
    };

    const handleChange = async (value, options) => {
        //console.log(value);
        try {
            setShowLoader(true)
            const data = new FormData();
            data.append("iBrandID", value);
            if (value == 'all') {
                localStorage.removeItem("iBrandID");
                const response = await HttpClient.post("/data/outletData");
                const json = response.data;
                if (json.status === 1) {
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setOutletData(json.outletList);
                    setTotalOutlet(json.your_stats.total_outlet);
                    setmerchantImage(json.merchant_info.merchant_image);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                    }
                   
                    setMyOffersVisible(false);

                } else {
                    console.error(json);
                }
            }
            else {

                const response = await HttpClient.post("/data/outletDataBrand", data);
                const json = response.data;
                if (json.status === 1) {
                    localStorage.setItem("iBrandID", value);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setOutletData(json.outletList);
                    setTotalOutlet(json.your_stats.total_outlet);
                    setmerchantImage(json.merchant_info.merchant_image);

                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report');
                        setMyOffersVisible(true);
                    }
                    window.location.reload();

                } else {
                    console.error(json);
                }
            }
        }
        catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    };

    const onSearch = (e) => {
        const query = e.target.value
        if (query) {
            const reg = new RegExp(query, "gi");
            const filterData = outletData.filter((row) => {
                const outletMatch = row.vOutletTitle.match(reg);
                const areaNameMatach = row.vAreaName.match(reg);
                return outletMatch || areaNameMatach;
            })
            setDataSource(filterData)
        } else {
            setDataSource(outletData)
        }
    };  
    const handleHelp = () => {
    
        var Name = localStorage.getItem("vEmail");
        const win = window.open(
          `https://travellerpasslive.com/com_merchant/check.html/?vEmail=${Name}`,
          "_blank"
        );
        win.focus();
      
    };


    // const dataSource = [
    //     {
    //         key: '1',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Business Bay',
    //         address: 'Business Bay',  
    //         redemptions: '709',      
    //         amount: '230'
    //     },
    //     {
    //         key: '2',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Marina',
    //         address: 'Marina',  
    //         redemptions: '657',      
    //         amount: '429'
    //     },
    //     {
    //         key: '3',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Business Bay',
    //         address: 'Business Bay',  
    //         redemptions: '709',      
    //         amount: '230'
    //     },
    //     {
    //         key: '4',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Business Bay',
    //         address: 'Business Bay',  
    //         redemptions: '709',      
    //         amount: '230'
    //     },
    //     {
    //         key: '5',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Al Karama',
    //         address: 'Al Karama',  
    //         redemptions: '213',      
    //         amount: '748'
    //     },
    //     {
    //         key: '6',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Jumeihra Village',
    //         address: 'Jumeihra Village',  
    //         redemptions: '859',      
    //         amount: '132'
    //     },
    //     {
    //         key: '7',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Financial Center',
    //         address: 'Financial Center',  
    //         redemptions: '12',      
    //         amount: '429'
    //     },
    //     {
    //         key: '8',
    //         platform: 'TravellerPass Cafe- UAE',
    //         image: tpIcon,
    //         transaction_id: '7478398767326',
    //         outlet_title: 'Traveller Prive , Test',
    //         area_name: 'Marina',
    //         address: 'Marina',  
    //         redemptions: '23',      
    //         amount: '132'
    //     },
    // ];

    //const dataSource = outletData;
    const columns = [
        {
            title: 'Platform',
            dataIndex: 'vOutletTitle',
            key: 'vOutletTitle',
            render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={merchantImage} alt={record.vOutletTitle} /></div> {record.vOutletTitle} </div>),
            sorter: (a, b) => a.vOutletTitle - b.vOutletTitle,
            width: 150
        },
        {
            title: 'Transaction ID',
            dataIndex: 'vOutletID',
            key: 'vOutletID',
            sorter: (a, b) => a.vReferenceID - b.vReferenceID,
            width: 130
        },
        {
            title: 'Outlet Title',
            dataIndex: 'vOutletTitle',
            key: 'vOutletTitle',
            sorter: (a, b) => a.vOutletTitle - b.vOutletTitle,
            width: 130
        },
        {
            title: 'Area Name',
            dataIndex: 'vAreaName',
            key: 'vAreaName',
            sorter: (a, b) => a.area_name - b.area_name,
            width: 120
        },
        {
            title: 'Address',
            dataIndex: 'vAreaName',
            key: 'vAreaName',
            sorter: (a, b) => a.vAreaName - b.vAreaName,
            width: 120
        },
        {
            title: 'Redemptions',
            dataIndex: 'voucherCount',
            key: 'voucherCount',
            sorter: (a, b) => a.voucherCount - b.voucherCount,
            width: 130
        },
        {
            title: 'Amount',
            dataIndex: 'vTotalAmount',
            key: 'vTotalAmount',
            sorter: (a, b) => a.vTotalAmount - b.vTotalAmount,
            render: (text) => (<div className='spent-col'><small>AED</small><div className='amount-col'>{text}</div></div>),
            width: 100
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: () => <Button type='secondary' className='download-btn'><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>,
            align: 'right',
            width: 60
        },
    ];
    const menu = (
        <Menu
            items={[
                // {
                //   key: '1',
                //   label: 'Generate Invoice for Payments',
                // },
                {
                    key: '2',
                    label: 'Download Report',
                },
            ]}
        />
    );
    return isLoadingInitialData ? null : (
        <div className='inner-wrapper'>
            <Row>
                <Col>
                    <div className='title-header'>
                        <p>Outlet Management</p>
                        <Select value={Selected}
                            popupClassName='header-top-select' onChange={handleChange}
                            suffixIcon={<SvgIcon name='chevron-down' viewbox='0 0 23.616 13.503' />}
                        >
                            <Option value="all">{MenuText}</Option>

                            {BrandInfo.map(item =>
                            (
                                <Option value={item.iBrandID}>{item.vBrandName}</Option>
                            )
                            )}

                        </Select>
                    </div>
                </Col>
                <Col>
                    <div className='need-help' onClick={handleHelp}>
                        <p><img src={helpTag} alt='need help' /> Need Help ?</p>                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='statcis-row'>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='payment' viewbox='0 0 15.842 12.5' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{totalSales}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Sales</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                            <SvgIcon className='total-sale-icon' name='total-sale' viewbox='0 0 99.973 46.186' />
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{totalRedemptions}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Redemption</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='wallet' viewbox='0 0 15.07 14.873' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{avgRedemptions}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Avg. Saving per user</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='map-marker' viewbox='0 0 10.371 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{totalOutlet}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Areas</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="filter-row">
                <Col md="5">
                    <div className='filter-left'>
                        <h3>Outlet Management</h3>
                        <Input onChange={onSearch} suffix={<SvgIcon name='search' viewbox='0 0 15.13 15.134' />} />
                    </div>
                </Col>
                <Col md="7" className='filter-right'>
                    {/* <div className='date-select-row'>
                        <div className='date-col'>
                            <label>Starting Date</label>
                            <DatePicker inputReadOnly placeholder="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                        <div className='date-col'>
                            <label>End Date</label>
                            <DatePicker inputReadOnly placeholder="DD/MM/YYYY" suffixIcon={<SvgIcon name='calendar' viewbox='0 0 38.269 36' />} />
                        </div>
                    </div>
                    <Button type='secondary' className='icons-btn send-btn'><SvgIcon name='send' viewbox='0 0 26.998 26.995' /></Button> */}

                    <div>
                    {/* <DatePickerPopover></DatePickerPopover> */}
                    </div>

                    <Dropdown overlayClassName='dropdown-popup-cus' overlay={menu}>
                        <Button type='secondary' className='icons-btn'><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col sm='12'>
                    <Table className='custom-table outletmgt-table' scroll={{ x: "100%", y: "1000px" }} dataSource={dataSource} columns={columns} showSorterTooltip={false} sortUpIcon={<SvgIcon name='heart' viewbox='0 0 14.297 12.626' />} />
                </Col>
            </Row>
        </div>
    )
};

export default OutletMgt