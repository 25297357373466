import React, { createContext, useContext, useState } from "react";
import BouncingDotsLoader from ".";

const SideBarMenuContext = createContext();

export const SideBarMenuContextProvider = ({ children }) => {
  const [isMyOffersVisible, setMyOffersVisible] = useState(true);

  return (
    <SideBarMenuContext.Provider value={{isMyOffersVisible, setMyOffersVisible }}>
        {children}
    </SideBarMenuContext.Provider>
  );
};

export const useSideBarMenuContext = () => {
  const {isMyOffersVisible, setMyOffersVisible } = useContext(SideBarMenuContext);
  return {
    isMyOffersVisible, setMyOffersVisible,
  };
};
