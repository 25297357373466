import React, { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import { useHistory, NavLink } from 'react-router-dom';
import { SvgIcon } from "../../common";
import "./index.scss";
import HttpClient from "../../../api/HttpClient";

import { useLoaderContext } from '../../../containers/Loader/LoaderContext';
import TamokaCafeLogo from '../../../assets/images/TamokaCafe-logo.png';
import appDownloadBg from '../../../assets/images/sidebar-img.png';
import { useSideBarMenuContext } from "../../../layouts/SideBarMenuContext";



const Sidebar = () => {
  const history = useHistory();
  var merchantName = localStorage.getItem('vMerchantName');
  const [totalSales, setTotalSales] = useState(0);
  const [isLoadingInitialData, setIsLoadingInitialDate] = useState(true)
  const [totalRedemptions, setTotalRedemptions] = useState(0);
  const [merchantImage, setmerchantImage] = useState('');
  const {isMyOffersVisible} = useSideBarMenuContext()
  const [BrandInfo, setBrandInfo] = useState([]);
  const { setShowLoader } = useLoaderContext();
  const [Selected, setSelected] = useState("");


  var iBrandID = localStorage.getItem("iBrandID");
  
  //console.log(iBrandID);
  // if (iBrandID !== null){
  //   setshowOffer(true);
  // }
  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
      
    for (let index = 0; index < BrandInfo.length; index++) {
    
        const brand = BrandInfo[index];
        if(iBrandID===null)
        setSelected(merchantName);
        if (brand.iBrandID === iBrandID) {
            setSelected(brand.vBrandName);
            setmerchantImage(brand.vBrandImage)
            break;
        }
    }

}, [BrandInfo])
  const fetchData = async () => {
    //console.log(showOffer);
    try {
      setShowLoader(true);
      setIsLoadingInitialDate(true);
      if (iBrandID !== null) {
        const response = await HttpClient.post("/data/outletDataBrand");
        const json = response.data;
        console.log(iBrandID);
        if (json.status === 1) {
          //console.log(json.your_stats.total_redemptions);
          setTotalSales(json.your_stats.total_sales_all);
          setTotalRedemptions(json.your_stats.total_redemptions_all);
          setmerchantImage(json.merchant_info.merchant_image);
         // setMyOffersVisible(true);
          setBrandInfo(json.merchant_info.brandInfo);
          setSelected(merchantName);
        } else {
          console.error(json);
        }
      }
      else {
        const response = await HttpClient.post("/data/outletData");
        const json = response.data;
        //console.log(iBrandID);
        if (json.status === 1) {
          //console.log(json.your_stats.total_redemptions);
          setTotalSales(json.your_stats.total_sales_all);
          setTotalRedemptions(json.your_stats.total_redemptions_all);
          setmerchantImage(json.merchant_info.merchant_image);
          setSelected(merchantName);

          if (json.merchant_info.brandInfo.length === 0) {
           // setMyOffersVisible(true);
          }
          else {
           // setMyOffersVisible(false);
          }
         

        } else {
          console.error(json);
        }

      }

    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false)
      setIsLoadingInitialDate(false)
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
    //window.location.reload();
  };

  const items = [
    { label: <NavLink to="/dashboard" activeClassName="selected"><SvgIcon name="dashboard" viewbox="0 0 11.95 11.95" /> Dashboard</NavLink>, key: 'menu-1' },
    { label: <NavLink to="/redemptions" activeClassName="selected"><SvgIcon name="redemptions" viewbox="0 0 11.95 11.95" /> Redemptions</NavLink>, key: 'menu-2' },
    { label: <NavLink to="/my-offers" activeClassName="selected"><SvgIcon name="my-offers" viewbox="0 0 11.95 11.95" /> My Offers</NavLink>, key: 'menu-3' },
    { label: <NavLink to="/outlet-mgt" activeClassName="selected"><SvgIcon name="outlet-mgt" viewbox="0 0 11.95 11.95" /> Outlet MGT</NavLink>, key: 'menu-4' },
  ];



  return isLoadingInitialData ? null : (
    <div className="sidebar-inner">
      <div className="sidebar-upper">
        <div className="logo-section">
          <img src={merchantImage} alt="logo" />
          <h3>{Selected}</h3>
        </div>
        <div className="menu-section">
          <Menu items={items.filter((value) => {
            if (!isMyOffersVisible) {
              return value.key !== 'menu-3'
            }
            return true
          })} />
        </div>
        <div className="overall-status">
          <label>Overall Status</label>
          <div className="os-col">
            <small>AED</small>
            <h3>{totalSales}</h3>
            <p>Total <br></br>Sales</p>
          </div>
          <div className="os-col">
            <small>AED</small>
            <h3>{totalRedemptions}</h3>
            <p>Total <br></br>Redemption</p>
          </div>
        </div>
      </div>
      <div className="sidebar-bottom">
        <div className="app-download">
          <img src={appDownloadBg} alt="App Download" />
          <div className="download-caption">
            <h4>Partner App</h4>
            <p>Download Now</p>
            <div className="btn-row">
                <Button onClick={() =>
                        window.open(
                          "https://apps.apple.com/ae/app/travellerpass-partner-app/id6450885732",
                          "_blank"
                        )
                      }>
                        <SvgIcon name="apple" viewbox="0 0 16.549 20.329" /></Button>
                <Button onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.traveller.merchant&hl=en&gl=US",
                          "_blank"
                        )
                      }>
                  <SvgIcon name="play-store" viewbox="0 0 15.629 17.278" /></Button>
              </div>
          </div>
        </div>
        <div className="logout-bottom">
          <div>
            Logout
          </div>
          <Button onClick={handleLogout}><SvgIcon name="logout" viewbox="0 0 14.324 12.978" /></Button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
