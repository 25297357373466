import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Input, Radio, Checkbox, Select } from 'antd';
import { Col, Row, SvgIcon } from '../../../components/common';
import Slider from "react-slick";
import HttpClient from "../../../api/HttpClient";
import "../../../assets/scss/plugins/slick-slider/slick.min.scss";
import "../../../assets/scss/plugins/slick-slider/slick-theme.min.scss";
import './index.scss';
import { showAlert } from "../../../common/alert-messages";

import offerTypeIcon from '../../../assets/images/2for1.png';
import offerTypeIcon1 from '../../../assets/images/specialoffer.png';
import offerTypeIcon2 from '../../../assets/images/discountoffer.png';
import outletBranch1 from '../../../assets/images/outlet1.png';
import outletBranch2 from '../../../assets/images/outlet2.png';
import outletBranch3 from '../../../assets/images/rr3.png';

const { TextArea } = Input;



function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <SvgIcon name='next-arrow' viewbox='0 0 6.14 10.563' />
        </div>
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <SvgIcon name='prev-arrow' viewbox='0 0 6.14 10.564' />
        </div>
    );
}

const AddOfferModal = () => {
    var dataInf = [{ "iBrandID": "", "vBrandName": "" }];
    const merchantID = localStorage.getItem('iMerchantID');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [OfferTitle, setOfferTitle] = useState("");
    const [Brand, setBrand] = useState(null);
    const [BrandInfo, setBrandInfo] = useState(dataInf);
    const [OfferDescription, setOfferDescription] = useState("");
    const [OutletBranch, setOutletBranch] = useState("");
    const [OutletInfo, setOutletInfo] = useState([]);
    const [OutletImage, setOutletImage] = useState("");
    const [OfferType, setOfferType] = useState("");
    // const [OutletSelected, setOutletSelected] = useState("");

    const { Option } = Select;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await HttpClient.post("/data/outletInfo");
            const json = response.data;

            if (json.status === 1) {

                setOutletInfo(json.outletList.map((outlet) => {
                    return { ...outlet, selected: false }
                }));

                if (json.merchant_info.brandInfo.length === 0) {
                    setBrandInfo([{ "iBrandID": json.merchant_info.iBrandID, "vBrandName": json.merchant_info.merchantname }]);
                }
                else {
                    setBrandInfo(json.merchant_info.brandInfo);
                }

            }
            else {
                console.error(json);
            }

        } catch (error) {
            console.error(error);
        } finally {
            //setShowLoader(false)
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handOfferTitle = (e) => {
        setOfferTitle(e.target.value);
    };
    const handOfferDescription = (e) => {
        setOfferDescription(e.target.value);
    };
    const handleOutletClick = (outletId) => {
        setOutletInfo(OutletInfo.map((outlet) => {
            if (outlet.iOutletID === outletId) {
                // setOutletSelected(outlet.vAreaName);
                return { ...outlet, selected: !outlet.selected }

            }
            return outlet

        }))

    }
    const handleSelectAllOutlet = (e) => {
        const checked = e.target.checked
        setOutletInfo(OutletInfo.map((outlet) => {
            // setOutletSelected('All Outlets');
            return { ...outlet, selected: checked }
        }))
    }
    const options = {
        labels: {
            confirmable: "Close",
            cancellable: "Cancel"
        }
    }
    const handleChange = async () => {
        const data = new FormData();
        const selectedOutlets = OutletInfo.filter(o => o.selected)
        //console.log(selectedOutlets);

        if (selectedOutlets.length <= 0) {
            showAlert.error("No outltes selected.")
            return
        }

        let areaName = ""
        if (OutletInfo.length === selectedOutlets.length) {
            areaName = "All Outlets"
        } else {
            areaName = selectedOutlets.map(o => o.vAreaName).join(",")
        }

        //console.log(areaName);

        if (OfferTitle === '') {
            showAlert.error("Please Fill up the required fields.");
        } else {

            setIsModalOpen(false);
            //console.log(OutletSelected);
            const data = new FormData();
            data.append("OutletSelected", areaName);
            data.append("OfferDescription", OfferDescription);
            data.append("OfferTitle", OfferTitle);
            data.append("OfferType", OfferType);
            data.append("Brand", Brand);

            // data.append("vBrandName", vBrandName);
            // data.append("iBrandID", iBrandID);
            //console.log()
            const response = await HttpClient.post("/data/createOfferRequest",data);   
            showAlert.success("Our team has been notified on the request.");
        }
    };
    const onChangeOfferType = (e) => {
        setOfferType(e.target.value);
    };
    const onChangeBrand = (value, options) => {
        //console.log(options.children);
        setBrand(options.children);
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2.1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.3,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.1,
                }
            },
        ]
    };
    const outletBranchData = [
        { key: 1, name: 'Dubai Fish', image: outletBranch1, address: 'Al Karama Center' },
        { key: 2, name: 'Tamoka Cafe', image: outletBranch2, address: 'Al Karama Center' },
        { key: 3, name: 'Dubai Dish', image: outletBranch3, address: 'Al Karama Center' },
        { key: 4, name: 'Tamoka Cafe', image: outletBranch1, address: 'Al Karama Center' },
    ]
    return (
        <>
            <div className='add-offer' onClick={showModal}>
                <div className='plus-icon'>
                    <SvgIcon name='plus' viewbox='0 0 21 21' />
                </div>
                <div className='redemptionchart-left'>
                    <h4>Add Offer</h4>
                </div>
            </div>
            <Modal className='addoffer-modal' width={500} centered title={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closeIcon={<SvgIcon name='close' viewbox='0 0 25 25' />}
                footer={null}
            >
                <h2>Create a Offer</h2>
                <Form
                    name="basic"
                    layout="vertical"
                    className='form-inner'
                >
                    <Row className="upper-header">
                        <Col lg='12'>
                            <h4>Offer Type</h4>
                            <p>Choose the deal format</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <Radio.Group defaultValue="b" onChange={onChangeOfferType} value={OfferType} buttonStyle="solid">
                                <Radio.Button value="2 for 1">
                                    <img src={offerTypeIcon} alt='Offer Type' />
                                    <label>2 for 1</label>
                                </Radio.Button>
                                <Radio.Button value="Discount Type">
                                    <img src={offerTypeIcon2} alt='Offer Type' />
                                    <label>Discount Type</label>
                                </Radio.Button>
                                <Radio.Button value="Special Offer">
                                    <img src={offerTypeIcon1} alt='Offer Type' />
                                    <label>Special</label>
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='6'>
                            <Form.Item
                                label="Offer Details"
                                name="offer_details"
                                rules={[{ required: true }]}
                            >
                                <Input value={OfferTitle} onChange={handOfferTitle} />
                            </Form.Item>
                        </Col>
                        <Col lg='6'>
                            <Form.Item
                                label="Brand"
                                name="brand"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    popupClassName='header-top-select'
                                    placement="bottomRight" onChange={onChangeBrand} value={Brand}
                                    suffixIcon={<SvgIcon name='select-arrow' viewbox='0 0 18 9' />}>
                                    {BrandInfo.map(item =>
                                    (
                                        <Option value={item.iBrandID}>{item.vBrandName}</Option>
                                    )
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <Form.Item
                                label="Terms & Conditions"
                                name="terms"
                                rules={[{ required: true }]}
                            >
                                <TextArea value={OfferDescription} onChange={handOfferDescription} placeholder='Enter' rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="upper-header mt-3">
                        <Col xs='7'>
                            <h4>Outlet (Branch)</h4>
                            <p>Choose the outlet</p>
                        </Col>
                        <Col xs='5' className='text-right'>
                            <label>Select All <Checkbox onChange={handleSelectAllOutlet} /></label>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <Slider {...settings}>
                                {OutletInfo.map(item =>
                                (
                                    <div key={item.iOutletID} onClick={() => { handleOutletClick(item.iOutletID) }}>
                                        <div className='recent-red-card md-card'>
                                            <div className='card-header'>
                                                <div className='right-img'>
                                                    <img src={item.vBrandImage} alt={item.vOutletTitle} />
                                                </div>
                                            </div>
                                            <div className='card-bottom'>
                                                <h4>{item.vAreaName}</h4>
                                                <p>ID {item.vOutletTitle}</p>
                                            </div>
                                            {item.selected && <div className="card-tick" />}
                                        </div>
                                    </div>
                                )
                                )}
                            </Slider>
                        </Col>
                    </Row>

                    <Form.Item className='mb-0'>
                        <Button type="primary" htmlType="submit" onClick={handleChange} block>
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
};

export default AddOfferModal