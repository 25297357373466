import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Input, Select, Table, Dropdown, Menu, Modal, Divider } from 'antd';
import { Col, Row, SvgIcon } from '../../components/common';
import HttpClient from "../../api/HttpClient";
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom';
import './index.scss';
import { showAlert } from "../../common/alert-messages";

import { useLoaderContext } from '../Loader/LoaderContext';
import inAppicon from '../../assets/images/inapp.png';
import moment from 'moment';
import helpTag from '../../assets/images/headphone.svg';
import { el } from 'date-fns/locale';
import { useSideBarMenuContext } from '../../layouts/SideBarMenuContext';
import DatePickerPopover from "./DatePickerPopover";

const Redemptions = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    var dataInf = [{ "iBrandID": "", "vBrandName": "" }];

    const [isLoadingInitialData, setIsLoadingInitialDate] = useState(true)
    const [totalSales, setTotalSales] = useState(0);
    const [totalRedemptions, setTotalRedemptions] = useState(0);
    const [avgRedemptions, setAvgRedemptions] = useState(0);
    const [likedBy, setLikedBy] = useState(0);
    const [voucherData, setVoucherData] = useState([]);
    const [VoucherDetails, setVoucherDetails] = useState([]);
    const [BrandInfo, setBrandInfo] = useState(dataInf);
    const [MenuText, setMenuText] = useState('All Time Report');
    const { setShowLoader } = useLoaderContext();
    const [dataInCSV, setDataInCSV] = useState("");
    const [dataSource, setDataSource] = useState([])
    const [Selected, setSelected] = useState("");
    const {isMyOffersVisible,setMyOffersVisible} = useSideBarMenuContext()


    const [DateFrom, setDateFrom] = useState(null);
    const [DateTo, setDateTo] = useState(null);


    const history = useHistory();
    const nowDate = new Date();
    const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    const currentDate = longEnUSFormatter.format(nowDate);
    var iBrandID = localStorage.getItem("iBrandID");
    const { Option } = Select;


    const monthNames = [ "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];
    const yearNames = [ "2016", "2017", "2018", "2019", "2020", "2021","2023", "2024", "2025", "2026", "2028", "2029","2030" ];
    useEffect(() => {
        fetchData();
        downloadCsv();

    }, []);

    useEffect(() => {
       
            for (let index = 0; index < BrandInfo.length; index++) {
            
                const brand = BrandInfo[index];
                if(iBrandID===null)
                setSelected('all');
                if (brand.iBrandID === iBrandID) {
                    //console.log(iBrandID);
                    setSelected(iBrandID);
                    break;
                }
            }
        
    }, [BrandInfo])

    useEffect(() => {
        setDataSource(voucherData);
    }, [voucherData]);

    const fetchData = async () => {
        try {

            setShowLoader(true);
            setIsLoadingInitialDate(true);
            if (iBrandID !== null) {
                const data = new FormData();
                data.append("start", 0);
                data.append("limit", 20);
                data.append("iBrandID", iBrandID);
                const response = await HttpClient.post("/data/voucherDataBrand", data);
                const json = response.data;

                if (json.status === 1) {
                    //console.log(json.voucherList);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setVoucherData(json.voucherList);
                    setLikedBy(json.merchant_info.likes);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report');
                        setMyOffersVisible(true);
                    }
                } else {
                    console.error(json);
                }


                // const selectedBrand = BrandInfo.map(item => {
                //     if (item.iBrandID === iBrandID) {
                //         return  iBrandID 
                //     } else {
                //         return ""
                //     }
                // })



                //console.log(Selected);
                //setSelected(selectedBrand);
            }
            else {
                const data = new FormData();
                data.append("start", 0);
                data.append("limit", 20);
                const response = await HttpClient.post("/data/voucherData");
                const json = response.data;

                if (json.status === 1) {
                    //console.log(json.voucherList);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setVoucherData(json.voucherList);
                    setLikedBy(json.merchant_info.likes);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                        setMyOffersVisible(false);
                    }
                } else {
                    console.error(json);
                }

            }

        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
            setIsLoadingInitialDate(false)
        }
    };

    const handleChange = async (value, options) => {
        //console.log(value);
        try {
            setShowLoader(true)
            const data = new FormData();
            data.append("iBrandID", value);
            if (value == 'all') {
                localStorage.removeItem("iBrandID");
                const response = await HttpClient.post("/data/voucherData");
                const json = response.data;
                if (json.status === 1) {

                    //console.log(json.voucherList);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setVoucherData(json.voucherList);
                    setLikedBy(json.merchant_info.likes);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                    }

                    setLikedBy(json.merchant_info.likes);
                    setMyOffersVisible(false);
                } else {
                    console.error(json);
                }
            }
            else {

                const response = await HttpClient.post("/data/voucherDataBrand", data);
                const json = response.data;
                if (json.status === 1) {
                    //console.log(json.lastMonths);
                    localStorage.setItem("iBrandID", value);
                    //console.log(json.voucherList);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setVoucherData(json.voucherList);
                    setLikedBy(json.merchant_info.likes);

                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report');
                        setMyOffersVisible(true);
                    }

                    setLikedBy(json.merchant_info.likes)
                } else {
                    console.error(json);
                }
            }
        }
        catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    };

    const handleFrom = (date, dateString) => {
        setDateFrom(date);
    };
    const handleTo = (date, dateString) => {
        setDateTo(date);

        if (DateFrom) {
            console.log(DateFrom.format("DD-MM-YYYY"));
        }


    };

    const handleDateRange = async () => {
        try {

            setShowLoader(true);
            const data = new FormData();
            //console.log(data);
            // var startDate = DateFrom.format("DD-MM-YYYY");
            // var endDate = DateTo.format("DD-MM-YYYY");

            // console.log(startDate);

            // console.log(endDate);

            data.append("startDate", DateFrom);
            data.append("endDate", DateTo);
            const response = await HttpClient.post("/data/voucherDataRange", data);
            const json = response.data;
            if (json.status === 1) {
                //console.log(json.voucherList);
                setTotalSales(json.your_stats.total_sales);
                setTotalRedemptions(json.your_stats.total_redemptions);
                setAvgRedemptions(json.your_stats.avg_spends);
                setVoucherData(json.voucherList);
                setLikedBy(json.merchant_info.likes);
                if (json.merchant_info.brandInfo.length === 0) {
                    setBrandInfo(dataInf);
                }
                else {
                    setBrandInfo(json.merchant_info.brandInfo);
                    setMenuText('All Brand Report')
                }
            } else {
                showAlert.error("Oops! Something went wrong.");
            }
        } catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
        finally {
            setShowLoader(false)
        }
    };
    const downloadCsv = async () => {
        try {
            const response = await HttpClient.post("/data/exportVoucherCSV");
            const json = response.data;
            setDataInCSV(json);

            //console.log(json);
        }
        catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
        finally {
            setShowLoader(false)
        }
    };

    const showModal = (record) => {
        //console.log(record);
        setVoucherDetails(record)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        //console.log(VoucherDetails);
        const file = new Blob(["Platform: " + VoucherDetails.source + "\nUser Email: " + VoucherDetails.vEmail + "\nAmount Spent: " + VoucherDetails.vTotalAmount + "\nCustomer Saved: " + VoucherDetails.vDiscountAmount + "\nDeal Name: " + VoucherDetails.iPercentageDiscount_r + "\nPayment Mode: Paid at Outlet" + "\nTransaction ID: " + VoucherDetails.iTransactionID + "\nUser Mobile: " + VoucherDetails.vMobileNo + "\nVoucher ID: " + VoucherDetails.iVoucherID + "\nBrand Name: " + VoucherDetails.vBrandName + "\nDate: " + VoucherDetails.invoices_date_printabble + "\nArea Name: " + VoucherDetails.vAreaName], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "Redemption Details.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click()
    };

    const onSearch = (e) => {
        const query = e.target.value
        if (query) {
            const reg = new RegExp(query, "gi");
            const filterData = voucherData.filter((row) => {
                const emailMatch = row.vEmail.match(reg);
                const txnIdMatach = row.iTransactionID.match(reg);
                return emailMatch || txnIdMatach;
            })
            setDataSource(filterData)
        } else {
            setDataSource(voucherData)
        }
    };  

    const onSearchPayment = (e) => {
       
        if (e) {
            if(e==='All'){
                console.log(e);
                setDataSource(voucherData);
            }
            else{
            const reg = new RegExp(e, "gi");
            const filterData = voucherData.filter((row) => {
                const paymentMatch = row.payment_mode.match(reg);
                return paymentMatch;
            })
            setDataSource(filterData)
             }
        } else {
            setDataSource(voucherData)
        }
    };
    
    const handleHelp = () => {
    
        var Name = localStorage.getItem("vEmail");
        const win = window.open(
          `https://travellerpasslive.com/com_merchant/check.html/?vEmail=${Name}`,
          "_blank"
        );
        win.focus();
      
    };

    const updateClipboard = () => {

        navigator.clipboard.writeText("Platform: " + VoucherDetails.source + "\nUser Email: " + VoucherDetails.vEmail + "\nAmount Spent: " + VoucherDetails.vTotalAmount + "\nCustomer Saved: " + VoucherDetails.vDiscountAmount + "\nDeal Name: " + VoucherDetails.iPercentageDiscount_r + "\nPayment Mode: Paid at Outlet" + "\nTransaction ID: " + VoucherDetails.iTransactionID + "\nUser Mobile: " + VoucherDetails.vMobileNo + "\nVoucher ID: " + VoucherDetails.iVoucherID + "\nBrand Name: " + VoucherDetails.vBrandName + "\nDate: " + VoucherDetails.invoices_date_printabble + "\nArea Name: " + VoucherDetails.vAreaName).then(
            () => {
                showAlert.success("Redemption Details been copied to clipboard.");
            },
            () => {
                showAlert.error("Oops! Something went wrong.");
            }
        );
    };

    // const dataSource = [
    //     {
    //         key: '1',
    //         platform: 'Deem Offers',
    //         image: Platform1,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: inAppicon,
    //         payment_mode: 'In-app payment',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '230'
    //     },
    //     {
    //         key: '2',
    //         platform: 'Ahli Rewards',
    //         image: Platform3,
    //         user_email: 'arvindpatel@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: appleicon,
    //         payment_mode: 'Apple Pay',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '230'
    //     },
    //     {
    //         key: '3',
    //         platform: 'CBD',
    //         image: Platform4,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: gpayicon,
    //         payment_mode: 'Gpay',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '429'
    //     },
    //     {
    //         key: '4',
    //         platform: 'ENBD Bank',
    //         image: Platform2,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: inAppicon,
    //         payment_mode: 'In-app payment',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '748'
    //     },
    //     {
    //         key: '5',
    //         platform: 'Deem Offers',
    //         image: Platform1,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: inAppicon,
    //         payment_mode: 'In-app payment',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '132'
    //     },
    //     {
    //         key: '6',
    //         platform: 'TP',
    //         image: Platform5,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: appleicon,
    //         payment_mode: 'Apple Pay',
    //         deal_name: 'Discount',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '230'
    //     },
    //     {
    //         key: '7',
    //         platform: 'CBD',
    //         image: Platform3,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: gpayicon,
    //         payment_mode: 'Gpay',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '748'
    //     },
    //     {
    //         key: '8',
    //         platform: 'Discover',
    //         image: Platform6,
    //         user_email: 'imcdigitaltest@gmail.com',
    //         transaction_id: '7478398767326',
    //         payment_mode_image: gpayicon,
    //         payment_mode: 'Paid at outlet',
    //         deal_name: '2 for 1',
    //         date: '12th Mar 2022',
    //         saved: 'AED 20',
    //         spent: '132'
    //     },
    // ];


    //console.log(dataVoucher);

    dataSource.map(el => {
        let date = new Date(el.tCreatedAt)
        el.invoices_date_printabble = date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear()
    })

    const columns = [
        {
            title: 'Platform',
            dataIndex: 'source',
            key: 'source',
            render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.appImage} alt={record.source} /></div> {record.platform} </div>),
            sorter: (a, b) => a.source - b.source,
            width: 120
        },
        {
            title: 'User Email',
            dataIndex: 'vEmail',
            key: 'vEmail',
            sorter: (a, b) => a.vEmail - b.vEmail,
            width: 150
        },
        {
            title: 'Transaction ID',
            dataIndex: 'iTransactionID',
            key: 'iTransactionID',
            sorter: (a, b) => a.iTransactionID - b.iTransactionID,
            width: 130
        },
        {
            title: 'Payment Mode',
            dataIndex: 'payment_mode',
            key: 'payment_mode',
            render: (text, record) => (<div className='name-with-icon'><div className='table-image-icon'><img src={record.payImage} alt={record.payment_mode} /></div> {record.payment_mode} </div>),
            sorter: (a, b) => a.payment_mode - b.payment_mode,
            width: 140
        },
        {
            title: 'Deal Name',
            dataIndex: 'iPercentageDiscount_r',
            key: 'iPercentageDiscount_r',
            sorter: (a, b) => a.iPercentageDiscount_r - b.iPercentageDiscount_r,
            width: 120
        },
        {
            title: 'Date',
            dataIndex: 'tCreatedAt',
            key: 'tCreatedAt',
            sorter: (a, b) => moment(a.tCreatedAt).unix() - moment(b.tCreatedAt).unix(),
            width: 120
        },
        {
            title: 'Saved',
            dataIndex: 'vDiscountAmount',
            key: 'vDiscountAmount',
            sorter: (a, b) => a.vDiscountAmount - b.vDiscountAmount,
            width: 100
        },
        {
            title: 'Spent',
            dataIndex: 'vTotalAmount',
            key: 'vTotalAmount',
            sorter: (a, b) => a.vTotalAmount - b.vTotalAmount,
            render: (text) => (<div className='spent-col'><small>AED</small><div className='amount-col'>{text}</div></div>),
            width: 90
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: () => <Button type='secondary' className='download-btn'><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>,
            align: 'right',
            width: 50
        },
    ];
    const menu = (
        <Menu
            items={[
                // {
                //     key: '1',
                //     label: 'Generate Invoice for Payments',
                // },
                {
                    key: '2',
                    label: 'Redemption Report',
                },
            ]}
        />
    );

    return isLoadingInitialData ? null : (
        <div className='inner-wrapper'>
            <Row>
                <Col>
                    <div className='title-header'>
                        <p>Redemptions</p>
                        <Select value={Selected}
                            popupClassName='header-top-select' onChange={handleChange}
                            suffixIcon={<SvgIcon name='chevron-down' viewbox='0 0 23.616 13.503' />}
                        >
                            <Option value="all">{MenuText}</Option>

                            {BrandInfo.map(item =>
                            (
                                <Option value={item.iBrandID}>{item.vBrandName}</Option>
                            )
                            )}

                        </Select>
                    </div>
                </Col>
                <Col>
                    <div className='need-help' onClick={handleHelp}>
                        <p><img src={helpTag} alt='need help' /> Need Help ?</p>                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='statcis-row'>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='payment' viewbox='0 0 15.842 12.5' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{totalSales}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Sales</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                            <SvgIcon className='total-sale-icon' name='total-sale' viewbox='0 0 99.973 46.186' />
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{totalRedemptions}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Redemption</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='wallet' viewbox='0 0 15.07 14.873' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{avgRedemptions}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Avg. Saving per user</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='heart' viewbox='0 0 14.297 12.626' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{likedBy}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Favorited by User</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="filter-row">
                <Col md="4">
                    <div className='filter-left'>
                        <h3>Redemptions</h3>
                        <Input onChange={onSearch} suffix={<SvgIcon name='search' viewbox='0 0 15.13 15.134' />} />
                    </div>
                </Col>
                <Col md="3" className='filter-right'>
                     <div className='date-select-row'>
                        <div className='date-col'>
                            <label>Payment Mode</label> 
                            <Select style={{width: 200,}}
                                    onChange={onSearchPayment}
                                    defaultValue="All"
                                    popupClassName='header-top-select'
                                    placement="bottomRight"
                                    suffixIcon={<SvgIcon name='select-arrow' viewbox='0 0 18 9' />}>
                                    <Option value="All">All</Option>
                                    <Option value="In-app payment">In app-Payments</Option>
                                    <Option value="Paid at outlet"> Paid at the Outlet</Option>
                            </Select>
                        </div>
                    </div>
                </Col>
                <Col md="5" className='filter-right'>
                    
                    <DatePickerPopover></DatePickerPopover>
                    <Button type='secondary' onClick={handleDateRange} className='icons-btn send-btn'><SvgIcon name='send' viewbox='0 0 26.998 26.995' /></Button>
                    <Dropdown overlayClassName='dropdown-popup-cus' overlay={menu}>
                        <a
                            href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                            download="RedemptionReport.csv"
                        ><Button type='secondary' onClick={downloadCsv} className='icons-btn'><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>
                        </a>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col sm='12'>
                    <Table className='custom-table redemption-table' scroll={{ x: "100%", y: "1000px" }} dataSource={dataSource} columns={columns} showSorterTooltip={false} sortUpIcon={<SvgIcon name='heart' viewbox='0 0 14.297 12.626' />}
                        onRow={(record) => {
                            return {
                                onClick: event => {
                                    showModal(record)
                                },
                            };
                        }}
                    />
                </Col>
            </Row>
            <Modal className='redemption-modal' width={500} centered title={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closeIcon={<SvgIcon name='close' viewbox='0 0 25 25' />}
                footer={
                    <>
                        <Button type='secondary' onClick={downloadTxtFile}><SvgIcon name='download' viewbox='0 0 15.624 15.996' /></Button>
                        {/* <Button type='secondary' onClick={updateClipboard}><SvgIcon name='share' viewbox='0 0 14.478 16.756' /></Button> */}
                    </>
                }
            >

                <Row className="upper-details">

                    <Col>
                        <div className='upper-image'>
                            <img src={VoucherDetails.appImage} alt={VoucherDetails.source} />
                        </div>
                        <h3>{VoucherDetails.source}</h3>
                        <p>User Email : {VoucherDetails.vEmail}</p>
                        <Divider />
                    </Col>
                </Row>

                <div className='bottom-details'>
                    <Row>
                        <Col>
                            <label>Amount Spent</label>
                        </Col>
                        <Col className='text-right'>AED {VoucherDetails.vTotalAmount}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Customer Saved</label>
                        </Col>
                        <Col className='text-right'>AED {VoucherDetails.vDiscountAmount}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Deal Name</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.iPercentageDiscount_r}</Col>
                    </Row>

                    <Row>
                        <Col>
                            <label>Payment Mode</label>
                        </Col>
                        <Col className='text-right'>
                            <div className='d-flex align-items-center justify-content-end'><div className='payment-icon'><img src={inAppicon} alt='In-app payment' /></div>
                            &nbsp;{VoucherDetails.payment_mode}
                                
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Transaction ID</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.iTransactionID}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>User Email</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.vEmail}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>User Mobile</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.vMobileNo}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Voucher ID</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.iVoucherID}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Brand Name</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.vBrandName}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Date</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.tCreatedAt}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Area Name</label>
                        </Col>
                        <Col className='text-right'>{VoucherDetails.vAreaName}</Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
};

export default Redemptions