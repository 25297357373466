import { Modal } from "antd";
import React from "react";
import "./index.scss";

const BouncingDotsLoader = (props) => {
  return (
    <>
      <Modal
        className="membershipid-modal-chk"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        open={props.isModalVisible}
        closable={false}
        bodyStyle={{ backgroundColor: "transparent" }} maskStyle={{ backgroundColor: '#0d1e33', backdropFilter: 'blur(4px)' }}
      >
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Modal>
    </>
  );
};

export default BouncingDotsLoader;
