import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { SvgIcon, FloatLabel } from '../../components/common';
import { showAlert } from "../../common/alert-messages";
import HttpClient from "../../api/HttpClient";
import { useHistory } from 'react-router-dom';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import BannerVideo from '../../assets/video/bg_merchant.mp4';
import { WS_BASE_URL, WS_BASE_URL_PRIVE } from '../../constants';

const Login = () => {
    const [vEmail, setvEmail] = useState("");
    const [vPassword, setvPassword] = useState("");
    const history = useHistory();

    const handle = (e) => { setvPassword(e.target.value) };
    const handleEmailChange = (e) => { setvEmail(e.target.value) };

    const handleEmailClick = () => {
        //console.log(vEmail);
        if (vEmail !== "") {
            if (vEmail.trim().length >= 2) {
                if (vEmail.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$") !== null)
                    checkEmail(vEmail, vPassword);
                else showAlert.error("Please enter Valid Email.");
            }
        } else {
            if (vEmail === "") showAlert.error("Please Enter your email.");
        }
    };
    const generateTravellerApiKey = async () => {
        try {
            const response = await HttpClient.post('/key', null, {
                headers: {
                    'X-API-KEY': '6d9f729b765aae27f45e5ef9150fa073f8a61b94',
                },
            });
            const key = response.data.key;
            HttpClient.defaults.headers = {
                'X-API-KEY': key,
            };
            localStorage.setItem('X-API-KEY', key);

        } catch (error) {
            console.error(error);
        }
    }
    const generatePriveApiKey = async () => {
        try {
            const response = await HttpClient.post('/key', null, {
                headers: {
                    'X-API-KEY': '45c48d3386f595c0ea7f55f0d4112ec1',
                },
            });
           const key = response.data.key;
            HttpClient.defaults.headers = {
                'X-API-KEY': key,
            };
            localStorage.setItem('X-API-KEY', key);

        } catch (error) {
            console.error(error);
        }
    }


    const checkEmail = async (vEmail, vPassword) => {
        try {

            HttpClient.defaults.baseURL = WS_BASE_URL
            await generateTravellerApiKey()

            const data = new FormData();
            //console.log(data);
            data.append("email", vEmail);
            data.append("password", vPassword);
            const response = await HttpClient.post("/login/prelogin", data);
            const json = response.data;
            if (json.status === 1) {
                //console.log(json.DATA[0]);
                //localStorage.clear();
                localStorage.setItem("iMerchantID", json.DATA.iMerchantID);
                localStorage.setItem("vMerchantName", json.DATA.merchantname);
                localStorage.setItem("vEmail", json.DATA.email);
                localStorage.setItem("accesstoken", json.DATA.accesstoken);
                localStorage.setItem("priveLogin", "false")


                const token = localStorage.getItem('accesstoken');
                const merchantID = localStorage.getItem('iMerchantID');

                if (token && merchantID) {
                    HttpClient.defaults.headers = {
                        ...HttpClient.defaults.headers,
                        accesstoken: token,
                        iMerchantID: merchantID
                    }
                }
                history.push("/dashboard");
            } else {

                checkEmailPrive(vEmail, vPassword)
                //showAlert.error(json.MESSAGE);
            }
        } catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
    };
    const checkEmailPrive = async (vEmail, vPassword) => {
        try {

            HttpClient.defaults.baseURL = WS_BASE_URL_PRIVE
            await generatePriveApiKey()

            const data = new FormData();
            //console.log(data);
            data.append("email", vEmail);
            data.append("password", vPassword);
            const response = await HttpClient.post("/login/prelogin", data);
            const json = response.data;
            if (json.status === 1) {
                //console.log(json.DATA[0]);
                //localStorage.clear();
                localStorage.setItem("iMerchantID", json.DATA.iMerchantID);
                localStorage.setItem("vMerchantName", json.DATA.merchantname);
                localStorage.setItem("vEmail", json.DATA.email);
                localStorage.setItem("accesstoken", json.DATA.accesstoken);
                localStorage.setItem("priveLogin", "true")


                const token = localStorage.getItem('accesstoken');
                const merchantID = localStorage.getItem('iMerchantID');

                if (token && merchantID) {
                    HttpClient.defaults.headers = {
                        ...HttpClient.defaults.headers,
                        accesstoken: token,
                        iMerchantID: merchantID
                    }
                }
                history.push("/dashboard");
            } else {




                showAlert.error(json.MESSAGE);
            }
        } catch (error) {
            console.error(error);
            showAlert.error("Oops! Something went wrong.");
        }
    };


    return (
        <div className='login-wrapper'>
            <video playsInline autoPlay muted loop className="banner-video">
                <source src={BannerVideo} />
            </video>
            <div className='login-inner'>
                <div className='login-head' data-aos="fade-down" data-aos-duration="500">
                    <img src={LogoIcon} alt='logo' />
                    <h1>Welcome</h1>
                    <h4>to our partner dashboard</h4>
                </div>
                <div className='login-card' data-aos="zoom-in" data-aos-duration="600">
                    <Form
                        name="basic"
                        autoComplete="off"
                        layout="vertical"
                        className='login-form'
                    >
                        <Form.Item name="username">
                            <Input prefix={<SvgIcon name='user-icon' viewbox='0 0 20 20' />} value={vEmail} placeholder="Email" onChange={handleEmailChange} />
                        </Form.Item>
                        <Form.Item>
                            <FloatLabel name="password">
                                <Input type="password" prefix={<SvgIcon name='password-icon' viewbox='0 0 20 20' />} value={vPassword} onChange={handle} placeholder="Password" />
                            </FloatLabel>
                        </Form.Item>
                        <Form.Item className='m-0 py-3'>
                            <Button onClick={handleEmailClick} className='with-arrow' type="primary" htmlType="submit" block>
                                Sign In <SvgIcon name='chevron-right' viewbox='0 0 9.639 16.45' />
                            </Button>
                        </Form.Item>
                        <p>2023 © TravellerPassLLC</p>
                    </Form>
                </div>
            </div>
        </div>
    )
};

export default Login