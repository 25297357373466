import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { Select } from 'antd';
import HttpClient from "../../api/HttpClient";
import { Col, Row, SvgIcon } from '../../components/common';
import Highcharts from "highcharts";
import Slider from "react-slick";
import HighchartsReact from "highcharts-react-official";
import "../../assets/scss/plugins/slick-slider/slick.min.scss";
import "../../assets/scss/plugins/slick-slider/slick-theme.min.scss";
import './index.scss';
import { format } from 'date-fns';
import helpTag from '../../assets/images/headphone.svg';
import recentRedemptions1 from '../../assets/images/rr1.png';
import recentRedemptions2 from '../../assets/images/rr2.png';
import recentRedemptions3 from '../../assets/images/rr3.png';
import recentRedemptions4 from '../../assets/images/rr4.png';
import BuyoneGetOne from '../../assets/images/buyone-getone.svg';
import Platform1 from '../../assets/images/pt1.png';
import Platform2 from '../../assets/images/pt2.png';
import Platform3 from '../../assets/images/pt3.png';
import Platform4 from '../../assets/images/pt4.png';
import AddOfferModal from '../MyOffers/AddOfferModal';
import { useLoaderContext } from '../Loader/LoaderContext';
import { useSideBarMenuContext } from '../../layouts/SideBarMenuContext';

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <SvgIcon name='next-arrow' viewbox='0 0 6.14 10.563' />
        </div>
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <SvgIcon name='prev-arrow' viewbox='0 0 6.14 10.564' />
        </div>
    );
}

const Dashboard = () => {

    var dataInf = [{ "iBrandID": "", "vBrandName": "" }];
    var merchantName = localStorage.getItem('vMerchantName');
    const [isLoadingInitialData, setIsLoadingInitialDate] = useState(true)
    const [totalSales, setTotalSales] = useState(0);
    const [totalRedemptions, setTotalRedemptions] = useState(0);
    const [avgRedemptions, setAvgRedemptions] = useState(0);
    const [likedBy, setLikedBy] = useState(0);
    const [lastRedemptions, setLastRedemptions] = useState([]);
    const [Platforms, setPlatform] = useState([]);
    const [Redemptions12Months, setRedemptions12Months] = useState([]);
    const [TopOffers, setTopOffers] = useState([]);
    const [ActiveOffers, setActiveOffers] = useState([]);
    const [LastMonths, setLastMonths] = useState([]);
    const [BrandInfo, setBrandInfo] = useState(dataInf);
    const [OfferCount, setOfferCount] = useState(0);

    const [MenuText, setMenuText] = useState('All Time Report');
    const [Selected, setSelected] = useState("");
    const { setShowLoader } = useLoaderContext();
    const { isMyOffersVisible, setMyOffersVisible } = useSideBarMenuContext()
    const sliderRef = useRef(null);

    const nowDate = new Date();
    const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    const currentDate = longEnUSFormatter.format(nowDate);
    var iBrandID = localStorage.getItem("iBrandID");

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {

        for (let index = 0; index < BrandInfo.length; index++) {

            const brand = BrandInfo[index];
            if (iBrandID === null)
                setSelected('all');
            if (brand.iBrandID === iBrandID) {
                //console.log(iBrandID);
                setSelected(iBrandID);
                break;
            }
        }

    }, [BrandInfo]);

    useEffect(() => {
        console.log(sliderRef);
        sliderRef.current?.slickGoTo(1, true)
    }, [lastRedemptions])

    const options = {
        chart: {
            type: "column",
            backgroundColor: null,
            height: 308
        },
        credit: {
            enabled: false,
        },
        title: {
            text: ""
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                maxPointWidth: 25
            },
            column: {
                borderRadiusTopLeft: 2,
                borderRadiusTopRight: 2,
                opacity: 1,
            }
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            title: null,
            color: 'red',
            className: 'chart-y-lebel',
            gridLineDashStyle: 'longdash',
            gridLineColor: '#334862',
        },
        xAxis: {
            categories: LastMonths,
            className: 'chart-x-lebel',
            lineWidth: 0
        },
        tooltip: {
            className: 'chart-tooltip',
            useHTML: true,
            padding: 0,
            borderWidth: 0,
            formatter: function () {
                return '<div class="chart-tooltip">' + '<div class="tooltip-left">' + '<b>' + this.y + '</b>' + '<br/>' + 'REDEMPTIONS' + '</div>' + '<div class="tooltip-right">' + this.x + '</div>' + '</div>';
            }
        },
        series: [
            {
                data: Redemptions12Months,
                color: '#FFB99F',
            }
        ]
    };
    const lineChart1 = {
        chart: {
            type: "spline",
            backgroundColor: null,
            height: "55",
            width: "100",
            margin: 0,
            padding: 0,
            spacing: 0
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            spline: {
                spacing: 0,
                size: "100%",
                showInLegend: false,
                lineWidth: 3,
                lineColor: "#6FC3CE",
                fillColor: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
        },
        yAxis: {
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            title: {
                enabled: false,
            },
        },
        xAxis: {
            lineColor: "transparent",
            labels: {
                enabled: false,
            },
        },
        series: [
            {
                data: [1, 3, 5, 5.5, 4, 3, 2, 2.5, 3, 1.5, 2, 4, 4.5, 4.8, 7.5, 7, 6,],
            },
        ],
    };
    const lineChart2 = {
        chart: {
            type: "spline",
            backgroundColor: null,
            height: "55",
            width: "100",
            padding: 0,
            margin: 0,
            spacing: 0
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            spline: {
                spacing: 0,
                size: "100%",
                showInLegend: false,
                lineWidth: 3,
                lineColor: "#FF7541",
                fillColor: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
        },
        yAxis: {
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            title: {
                enabled: false,
            },
        },
        xAxis: {
            lineColor: "transparent",
            labels: {
                enabled: false,
            },
        },
        series: [
            {
                data: [1.5, 1, 2, 3, 2.5, 2, 2.5, 3.5, 4, 5, 4.5, 1, 1.5, 2.5, 3.5, 4.5, 2.5, 1.5, 2.5],
            },
        ],
    };
    const lineChart3 = {
        chart: {
            type: "spline",
            backgroundColor: null,
            height: "55",
            width: "100",
            margin: 0,
            padding: 0,
            spacing: 0
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            spline: {
                spacing: 0,
                size: "100%",
                showInLegend: false,
                lineWidth: 3,
                lineColor: "#FF7541",
                fillColor: false,
                radius: 8,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 8,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
        },
        yAxis: {
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            title: {
                enabled: false,
            },
        },
        xAxis: {
            lineColor: "transparent",
            labels: {
                enabled: false,
            },
        },
        series: [
            {
                data: [3, 2, 1.5, 1.8, 4, 2, 2.5, 3.5, 4.2, 4.8, 5.5, 5.8, 1, 1.3, 2.5, 3.5, 4.5, 2.5, 1.5, 1],
            },
        ],
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.2,
                }
            },
        ]
    };
    const recentRedemptionsData = [
        { key: 1, name: 'Karama', image: recentRedemptions1, points: '20', id: '64377758785', date: '12th Jan 2022' },
        { key: 2, name: 'Business Bay', image: recentRedemptions2, points: '20', id: '64377758785', date: '12th Jan 2022' },
        { key: 3, name: 'Business Bay', image: recentRedemptions3, points: '20', id: '64377758785', date: '12th Jan 2022' },
        { key: 4, name: 'Business Bay', image: recentRedemptions1, points: '20', id: '64377758785', date: '12th Jan 2022' },
        { key: 5, name: 'Business Bay', image: recentRedemptions4, points: '20', id: '64377758785', date: '12th Jan 2022' },
        { key: 6, name: 'Business Bay', image: recentRedemptions4, points: '20', id: '64377758785', date: '12th Jan 2022' },
    ]
    const platformData = [
        { key: 1, name: 'Deem Offers', image: Platform1, price: '32578' },
        { key: 2, name: 'ENBD Bank', image: Platform2, price: '657348' },
        { key: 3, name: 'Ahli Rewards', image: Platform3, price: '53647' },
        { key: 4, name: 'CBD', image: Platform4, price: '5574' },
        { key: 5, name: 'Deem Offers', image: Platform1, price: '32578' },
        { key: 6, name: 'ENBD Bank', image: Platform2, price: '657348' },
        { key: 7, name: 'Ahli Rewards', image: Platform3, price: '53647' },
        { key: 8, name: 'CBD', image: Platform4, price: '5574' },
        { key: 9, name: 'Deem Offers', image: Platform1, price: '32578' },
        { key: 10, name: 'ENBD Bank', image: Platform2, price: '657348' },
        { key: 11, name: 'Ahli Rewards', image: Platform3, price: '53647' },
        { key: 12, name: 'CBD', image: Platform4, price: '5574' },
    ]

    const { Option } = Select;

    const fetchData = async () => {
        try {

            setShowLoader(true)
            setIsLoadingInitialDate(true);
            //console.log(isMyOffersVisible);
            if (iBrandID !== null) {
                const data = new FormData();
                data.append("iBrandID", iBrandID);
                const response = await HttpClient.post("/data/brandInfo", data);
                const json = response.data;
                if (json.status === 1) {
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setLastRedemptions(json.lastRedemptions);
                    setPlatform(json.topProjects);
                    if (json.lastMonths[0] === "") {
                        setRedemptions12Months(json.redemptionLast12Months.reverse());
                        setLastMonths(json.lastMonths.reverse());
                    }
                    else {
                        setRedemptions12Months(json.redemptionLast12Months);
                        setLastMonths(json.lastMonths);
                    }
                    setTopOffers(json.topDeals);
                    setActiveOffers(json.activeOfferDeals);
                    setOfferCount(json.activeOfferDeals.length);


                    if (json.merchant_info.brandInfo.length === 0) {
                        setMyOffersVisible(true);
                        setBrandInfo(dataInf);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMyOffersVisible(true);
                        setMenuText('All Brands Report')
                    }

                    setLikedBy(json.merchant_info.likes);
                } else {
                    console.error(json);
                }
            }
            else {

                localStorage.removeItem("iBrandID");
                const response = await HttpClient.post("/data/homePage");
                const json = response.data;

                //console.log(json.redemptionLast12Months.reverse());
                //console.log(json.lastMonths.reverse());
                //console.log(json.lastMonths[0]);
                if (json.status === 1) {
                    //console.log(json.lastMonths);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setLastRedemptions(json.lastRedemptions);
                    setPlatform(json.topProjects);
                    if (json.lastMonths[0] === "") {
                        setRedemptions12Months(json.redemptionLast12Months.reverse());
                        setLastMonths(json.lastMonths.reverse());
                    }
                    else {
                        setRedemptions12Months(json.redemptionLast12Months);
                        setLastMonths(json.lastMonths);
                    }
                    setTopOffers(json.topDeals);
                    setActiveOffers(json.activeOfferDeals);
                    setOfferCount(json.activeOfferDeals.length);
                    //console.log(json.merchant_info.brandInfo.length);

                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMyOffersVisible(false);
                        setMenuText('All Brand Report')
                    }

                    setLikedBy(json.merchant_info.likes);
                } else {
                    console.error(json);
                }
            }

        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
            setIsLoadingInitialDate(false)
        }
    };
    const handleChange = async (value, options) => {
        //console.log(value);
        try {
            setShowLoader(true)
            //console.log(isMyOffersVisible);
            const data = new FormData();
            data.append("iBrandID", value);
            if (value == 'all') {
                const response = await HttpClient.post("/data/homePage");
                const json = response.data;
                if (json.status === 1) {
                    //console.log(json.lastMonths);
                    localStorage.removeItem("iBrandID");
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setLastRedemptions(json.lastRedemptions);
                    setPlatform(json.topProjects);
                    if (json.lastMonths[0] === "") {
                        setRedemptions12Months(json.redemptionLast12Months.reverse());
                        setLastMonths(json.lastMonths.reverse());
                    }
                    else {
                        setRedemptions12Months(json.redemptionLast12Months);
                        setLastMonths(json.lastMonths);
                    }
                    setTopOffers(json.topDeals);
                    setActiveOffers(json.activeOfferDeals);
                    setOfferCount(json.activeOfferDeals.length);

                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                        setMyOffersVisible(false);
                    }

                    setLikedBy(json.merchant_info.likes);
                    //window.location.reload();
                    console.log(isMyOffersVisible);
                } else {
                    console.error(json);
                }
            }
            else {

                const response = await HttpClient.post("/data/brandInfo", data);
                const json = response.data;
                if (json.status === 1) {
                    //console.log(json.lastMonths);
                    localStorage.setItem("iBrandID", value);
                    setTotalSales(json.your_stats.total_sales);
                    setTotalRedemptions(json.your_stats.total_redemptions);
                    setAvgRedemptions(json.your_stats.avg_spends);
                    setLastRedemptions(json.lastRedemptions);
                    setPlatform(json.topProjects);
                    if (json.lastMonths[0] === "") {
                        setRedemptions12Months(json.redemptionLast12Months.reverse());
                        setLastMonths(json.lastMonths.reverse());
                    }
                    else {
                        setRedemptions12Months(json.redemptionLast12Months);
                        setLastMonths(json.lastMonths);
                    }
                    setTopOffers(json.topDeals);
                    setActiveOffers(json.activeOfferDeals);
                    setOfferCount(json.activeOfferDeals.length);

                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                        setMyOffersVisible(true);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMyOffersVisible(true);
                        setMenuText('All Brand Report')
                    }

                    setLikedBy(json.merchant_info.likes);
                    //window.location.reload();

                    console.log(isMyOffersVisible);
                } else {
                    console.error(json);
                }
            }
        }
        catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    };

    const handleHelp = () => {

        var Name = localStorage.getItem("vEmail");
        const win = window.open(
            `https://travellerpasslive.com/com_merchant/check.html/?vEmail=${Name}`,
            "_blank"
        );
        win.focus();

    };


    return isLoadingInitialData ? null : (
        <div className='inner-wrapper'>
            <Row>
                <Col>
                    <div className='title-header'>
                        <p>Hi,👋</p>
                        <Select value={Selected}
                            popupClassName='header-top-select' onChange={handleChange}
                            suffixIcon={<SvgIcon name='chevron-down' viewbox='0 0 23.616 13.503' />}
                        >
                            <Option value="all">{MenuText}</Option>

                            {
                                BrandInfo?.map(item =>
                                (
                                    <Option value={item.iBrandID}>{item.vBrandName}</Option>
                                )
                                )}

                        </Select>

                    </div>
                </Col>
                <Col>
                    <div className='need-help' onClick={handleHelp}>
                        <p><img src={helpTag} alt='need help' /> Need Help ?</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='statcis-row'>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='payment' viewbox='0 0 15.842 12.5' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{totalSales}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Sales</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                            <SvgIcon className='total-sale-icon' name='total-sale' viewbox='0 0 99.973 46.186' />
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='offer' viewbox='0 0 15.532 15.553' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{totalRedemptions}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Total Redemption</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='wallet' viewbox='0 0 15.07 14.873' />
                                </div>
                                <div className='item-upper'>
                                    <small>AED</small>
                                    <h3>{avgRedemptions}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Avg. Saving per user</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                        <li className='statcis-item'>
                            <div className='statcis-item-inner'>
                                <div className='top-icon'>
                                    <SvgIcon name='heart' viewbox='0 0 14.297 12.626' />
                                </div>
                                <div className='item-upper'>
                                    <small>&nbsp;</small>
                                    <h3>{likedBy}</h3>
                                </div>
                                <div className='item-bottom'>
                                    <p>Favorited by User</p>
                                    <small>{currentDate}</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <div className='md-card left-chart-card'>
                        <div className='card-header d-flex justify-content-between mb-3'>
                            <h4>Redemptions</h4>
                            <label>12 months from last redemption</label>
                        </div>
                        <div className='card-body'>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                    </div>
                </Col>
                <Col lg="4">
                    <div className='md-card right-chart-card'>
                        <div className='card-header d-flex justify-content-between mb-3'>
                            <div>
                                <h4 className='mb-1'>Offers</h4>
                                <label>Redemptions</label>
                            </div>
                            <div>
                                <label>12 months from last redemption</label>
                            </div>
                        </div>
                        <div className='card-body'>

                            <div className='offer-list'>
                                {TopOffers.length ? TopOffers.map(item =>
                                (
                                    <div className='offer-redemptionchart-row'>
                                        <div className='redemptionchart-left'>
                                            <p>AED</p>
                                            <h4>{item.netSales}</h4>
                                            {(item.iPercentageDiscount === '2 for 1') ? (
                                                <label>{item.iPercentageDiscount}<br />
                                                    {item.sTearmsConditions}</label>
                                            ) : (

                                                <label>{item.vDealTitle}</label>
                                            )}
                                        </div>
                                        {/* <div className='redemptionchart-right'>
                                    <HighchartsReact highcharts={Highcharts} options={lineChart1} />
                                </div> */}
                                    </div>
                                )
                                ) : <p>No Active Offers</p>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="recent-redemptions-row">
                <Col sm='12'>
                    <h3 className='title'>Recent Redemptions</h3>
                    <Slider ref={sliderRef} {...settings}>
                        {lastRedemptions.map(item =>
                        (
                            <div key={item.dVoucherID}>
                                <div className='recent-red-card md-card'>
                                    <div className='card-header'>
                                        <div>
                                            <p>AED</p>
                                            <h3>{item.vTotalAmount}</h3>
                                        </div>
                                        <div className='right-img'>
                                            <img src={item.appImage} alt={item.source} />
                                        </div>
                                    </div>
                                    <div className='card-bottom'>
                                        <h4>{item.vAreaName}</h4>
                                        <p>ID {item.iTransactionID} / {item.tCreatedAt}</p>
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                    </Slider>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <div className='md-card platforms-card'>
                        <div className='card-header d-flex justify-content-between mb-2'>
                            <div>
                                <h4 className='mb-1'>Platforms</h4>
                                <label>12 months from last redemption</label>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='platform-list'>
                                {Platforms.map(item =>
                                (
                                    <div className='platform-list-item' key={item.dVoucherID}>
                                        <div className='platform-list-left'>
                                            <div className='left-image'>
                                                <img src={item.appImage} alt={item.source} />
                                            </div>
                                            <div>
                                                {item.source}
                                            </div>
                                        </div>
                                        <div className='platform-list-right'>
                                            <small>AED</small>
                                            <h3>{item.netSales}</h3>
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg="4">
                    <div className='md-card activeoffer-card'>
                        <div className='card-header d-flex justify-content-between mb-3'>
                            <div>
                                <h4 className='mb-1'>Active Offers</h4>
                                <label>{OfferCount} Offers are active</label>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='active-list'>
                                {ActiveOffers.map(item =>
                                (

                                    <div className='activeoffer-row'>
                                        <div className='activeoffer-img'>
                                            <img src={item.appImage} alt={item.vDealTitle} />
                                        </div>
                                        {(item.iPercentageDiscount === '2 for 1') ? (
                                            <div className='redemptionchart-left'>
                                                <h4>{item.iPercentageDiscount}</h4>
                                                <label>{item.sTearmsConditions}</label>
                                            </div>
                                        ) : (
                                            <div className='redemptionchart-left'>
                                                <h4>{item.vDealTitle}</h4>
                                                {/* <label>{item.ExpDate}</label> */}
                                            </div>
                                        )}
                                    </div>
                                )
                                )}
                                {isMyOffersVisible === true && (
                                    <AddOfferModal />
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default Dashboard