import React, { useState, useEffect } from 'react';
import { Select, Switch } from 'antd';
import { Col, Row, SvgIcon } from '../../components/common';
import './index.scss';
import HttpClient from "../../api/HttpClient";
import { showAlert } from "../../common/alert-messages";

import { useLoaderContext } from '../Loader/LoaderContext';
import offerTag from '../../assets/images/offer-tag.svg';
import helpTag from '../../assets/images/headphone.svg';
import AddOfferModal from './AddOfferModal';
import { confirm } from "react-confirm-box";

const MyOffers = () => {

    var dataInf = [{ "iBrandID": "", "vBrandName": "" }];
    var merchantName = localStorage.getItem('vMerchantName');
    var iBrandID = localStorage.getItem("iBrandID");

    const [offerData, setofferData] = useState([]);
    const [isLoadingInitialData, setIsLoadingInitialDate] = useState(true)
    const [BrandInfo, setBrandInfo] = useState(dataInf);
    const [MenuText, setMenuText] = useState('All Time Report');
    const [likedBy, setLikedBy] = useState(0);
    const [Selected, setSelected] = useState("");
    // const [currentValue, setCurrentValue] = useState(true)
    const { setShowLoader } = useLoaderContext();
    const { Option } = Select;
    var iBrandID = localStorage.getItem("iBrandID");
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
       
        for (let index = 0; index < BrandInfo.length; index++) {
        
            const brand = BrandInfo[index];
            if(iBrandID===null)
            setSelected('all');
            if (brand.iBrandID === iBrandID) {
                //console.log(iBrandID);
                setSelected(iBrandID);
                break;
            }
        }
    
}, [BrandInfo])


    const fetchData = async () => {
        try {
            setShowLoader(true)
            setIsLoadingInitialDate(true);

            
            if (iBrandID !== null) {

                const data = new FormData();
                data.append("iBrandID", iBrandID);
                const response = await HttpClient.post("/data/myoffersBrand",data);
                const json = response.data;
    
                if (json.status === 1) {
                    setofferData(json.activeOfferDeals);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                    }
                } else {
                    console.error(json);
                }
            }
            else{
                

            const data = new FormData();
            const response = await HttpClient.post("/data/myoffers");
            const json = response.data;

            if (json.status === 1) {
                setofferData(json.activeOfferDeals);
                if (json.merchant_info.brandInfo.length === 0) {
                    setBrandInfo(dataInf);
                }
                else {
                    setBrandInfo(json.merchant_info.brandInfo);
                    setMenuText('All Brand Report')
                }
            } else {
                console.error(json);
            }
            }

        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
            setIsLoadingInitialDate(false);
        }
    };
    const options = {
        labels: {
          confirmable: "Close",
          cancellable: "Cancel"
        }
      }
    const handleChange = async (value) => {

        const result = await confirm("Are you sure you want to continue with this request?");
        if (result) {
        const newData = offerData.map(item => {
            if (item.iDealID === value.iDealID) {
                return { ...item, eStatus: value.eStatus === "Active" ? "Inactive" : "Active" }
            } else {
                return item
            }
        })
        const data = new FormData();
        data.append("iDealID", value.iDealID);
        data.append("vDealTitle", value.vDealTitle);
        data.append("vBrandName", value.vBrandName);
        data.append("sTearmsConditions", value.sTearmsConditions);
        data.append("eStatus", value.eStatus);
        const response = await HttpClient.post("/data/offerActivationWeb",data);   
       //confirm("Our team has been notified on the request.",options);
       showAlert.success("Our team has been notified on the request.");
       setofferData(newData);
    }
    };

    const handleChangeBrand = async (value,options) => {
        //console.log(value);
        try {
            setShowLoader(true)
            const data = new FormData();
            data.append("iBrandID", value);
            if (value == 'all') {
                localStorage.removeItem("iBrandID");
                const response = await HttpClient.post("/data/myoffers");
                const json = response.data;
                
                if (json.status === 1) {
                    setofferData(json.activeOfferDeals);
                    if (json.merchant_info.brandInfo.length === 0) {
                        setBrandInfo(dataInf);
                    }
                    else {
                        setBrandInfo(json.merchant_info.brandInfo);
                        setMenuText('All Brand Report')
                    }
                    
                    setLikedBy(json.merchant_info.likes);
                    window.location.reload();
                }else {
                    console.error(json);
                }
            }
            else {

                const response = await HttpClient.post("/data/myoffersBrand", data);
                const json = response.data;
                if (json.status === 1) {
                    //console.log(json.lastMonths);
                    localStorage.setItem("iBrandID", value);
                
                    if (json.status === 1) {
                        setofferData(json.activeOfferDeals);
                        if (json.merchant_info.brandInfo.length === 0) {
                            setBrandInfo(dataInf);
                        }
                        else {
                            setBrandInfo(json.merchant_info.brandInfo);
                            setMenuText('All Brand Report')
                        }
                        
                        setLikedBy(json.merchant_info.likes);

                    setLikedBy(json.merchant_info.likes);
                    window.location.reload();
                } else {
                    console.error(json);
                }
            }
        }
    }
        catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
      };
      const handleHelp = () => {
    
        var Name = localStorage.getItem("vEmail");
        const win = window.open(
          `https://travellerpasslive.com/com_merchant/check.html/?vEmail=${Name}`,
          "_blank"
        );
        win.focus();
      
    };

    return isLoadingInitialData ? null : (
        <div className='inner-wrapper'>
            <Row>
                <Col>
                    <div className='title-header'>
                        <p>My Offers</p>
                        <Select value={Selected}
                            popupClassName='header-top-select'   onChange={handleChangeBrand}
                            suffixIcon={<SvgIcon name='chevron-down' viewbox='0 0 23.616 13.503' />}
                        >
                            <Option value="all">{MenuText}</Option>

                            {BrandInfo.map(item =>
                            (
                                <Option value={item.iBrandID}>{item.vBrandName}</Option>
                            )
                            )}

                        </Select>
                    </div>
                </Col>
                <Col>
                    <div className='need-help' onClick={handleHelp}>
                        <p><img src={helpTag} alt='need help' /> Need Help ?</p>                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='myoffer-list'>

                        {offerData.map(item =>
                        (
                            <li>
                                <div className='offer-cards'>
                                    <div className='offer-cards-upper'>
                                        <div className='left-image'>
                                            <img src={item.appImage} alt={item.vDealTitle} />
                                        </div>
                                        {(item.iPercentageDiscount==='2 for 1') ? (
                                            <div>
                                            <h3>{item.iPercentageDiscount}</h3>
                                            <p>{item.sTearmsConditions}</p>
                                            </div>
                                        ):(
                                        <div>
                                            <h3>{item.vDealTitle}</h3>
                                        </div>
                                        )}
                                        
                                    </div>
                                    <div className='offer-cards-bottom'>
                                        <div className='left-col'>
                                            <SvgIcon name='outlet-icon' viewbox='0 0 17.247 17.242' />
                                            {item.vBrandName}
                                        </div>
                                        {/* {item.eStatus==='Active' ? 'Yes':'No'} */}
                                        <Switch checked={item.eStatus === 'Active'} onChange={() => {
                                            handleChange(item)
                                        }} />
                                        {/* <p>Current Mode of Switch: {`${currentValue}`} </p> */}
                                    </div>
                                </div>
                            </li>

                        )
                        )}

                        {/* <li>
                            <div className='offer-cards'>
                                <div className='offer-cards-upper'>
                                    <div className='left-image'>
                                        <img src={offerTag} alt='Buy 1 get 1 free' />
                                    </div>
                                    <div>
                                        <h3>Buy 1 get 1 free</h3>
                                        <p>Complimentary main course when a main course is purchased.</p>
                                    </div>
                                </div>
                                <div className='offer-cards-bottom'>
                                    <div className='left-col'>
                                        <SvgIcon name='outlet-icon' viewbox='0 0 17.247 17.242' />
                                        Tamoka Cafe / Unlimited
                                    </div>
                                    <Switch />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='offer-cards'>
                                <div className='offer-cards-upper'>
                                    <div className='left-image'>
                                        <img src={offerTag} alt='30%' />
                                    </div>
                                    <div>
                                        <h3>30%</h3>
                                        <p>Discount of 30% on total bill Valid on dine-in only</p>
                                    </div>
                                </div>
                                <div className='offer-cards-bottom'>
                                    <div className='left-col'>
                                        <SvgIcon name='outlet-icon' viewbox='0 0 17.247 17.242' />
                                        All Outlets / Unlimited
                                    </div>
                                    <Switch />
                                </div>
                            </div>
                        </li> */}
                        <li>
                            <AddOfferModal />
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
};

export default MyOffers