import SvgSprite from "./utility/SvgSpriteLoader";
import { rotues } from "./routes";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import history from "./common/history";
import AOS from "aos";
import "aos/dist/aos.css";
import HttpClient from "./api/HttpClient";
//Svg Sprite
import svgFile from "./assets/images/svg/svg-sprite.svg";
import { LoaderContextProvider } from "./containers/Loader/LoaderContext";
import { WS_BASE_URL, WS_BASE_URL_PRIVE } from "./constants";
import { SideBarMenuContextProvider } from "./layouts/SideBarMenuContext";

function App() {
  // AOS.init();
  const [isHttpClientInitilized, setHttpClientInitilized] = useState(false);

  useEffect(() => {
    AOS.init({});
    checkApiKey();
  }, []);

  const checkApiKey = async () => {
    let key = localStorage.getItem("X-API-KEY");
    if (key) {
      HttpClient.defaults.headers = {
        "X-API-KEY": key,
      };
    }

    const token = localStorage.getItem("accesstoken");
    const merchantID = localStorage.getItem("iMerchantID");
    const priveLogin = localStorage.getItem("priveLogin") === "true";

    if (priveLogin) {
      HttpClient.defaults.baseURL = WS_BASE_URL_PRIVE;
    } else {
      HttpClient.defaults.baseURL = WS_BASE_URL;
    }
    if (token && merchantID) {
      HttpClient.defaults.headers = {
        ...HttpClient.defaults.headers,
        accesstoken: token,
        iMerchantID: merchantID,
      };
    }
    setHttpClientInitilized(true);
  };

  if (!isHttpClientInitilized) {
    return null;
  }

  return (
    <>
      <SvgSprite url={svgFile} />
      <SideBarMenuContextProvider>
      <LoaderContextProvider>
        <Router history={history} basename={process.env.PUBLIC_URL || ""}>
          {rotues.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
        </Router>
      </LoaderContextProvider>
      </SideBarMenuContextProvider>
    </>
  );
}
export default App;