import React from "react";
import Dashboard from "./containers/Dashboard";
import Login from "./containers/Login";
import MyOffers from "./containers/MyOffers";
import OutletMgt from "./containers/OutletMgt";
import Redemptions from "./containers/Redemptions";

// Layout Types
import { defaultLayout, authLayout } from "./layouts";

// Route Views

export const rotues = [
  {
    path: "/",
    exact: true,
    layout: authLayout,
    component: () => <Login />,
  },
  {
    path: "/dashboard",
    layout: defaultLayout,
    component: () => <Dashboard />,
  },
  {
    path: "/redemptions",
    layout: defaultLayout,
    component: () => <Redemptions />,
  },
  {
    path: "/my-offers",
    layout: defaultLayout,
    component: () => <MyOffers />,
  },
  {
    path: "/outlet-mgt",
    layout: defaultLayout,
    component: () => <OutletMgt />,
  }
];
